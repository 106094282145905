
.homePage{
  
    width: 500px;
    height: 500px;
    padding: 2rem;
    background-color: #2d343e;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
  
    a {
      font-size: 30px;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: #8dff2f;
        bottom: -5px;
        left: 0;
        transform-origin: right;
        transform: scale(0);
        transition: transform 0.3s ease-in-out;
      }
      &:hover::before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  
    button {
      font-size: 1rem;
      padding: 0.8rem 1rem;
      font-weight: bold;
      width: 100px;
      border: none;
      background-color: #8dff2f;
      border-radius: 5px;
      color: #000;
      &:hover {
        cursor: pointer;
      }
    }
  
    p {
      color: lightblue;
      a {
        font-size: small;
        background-color: #272e36;
        padding: 5px;
        border-radius: 5px;
        color: #fff;
        font-weight: 800;
      }
    }
  }
  
  .textarea {
    width: 500px;
    min-height: 80px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    background-color: #404450;
    border: none;
    padding-left: 1rem;
    border-radius: 5px;
    &:focus {
      outline: 2px solid #8dff2f;
    }
  }
  
  .rotatingText-adjective {
    font-style: normal;
    font-size: x-large;
    font-weight: 500;
    left: 0;
    margin-bottom: 0;
    margin-top: 50px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    opacity: 0;
    color: #8dff2f;
  }
  
  .show {
    animation: infinite rotate;
    animation-duration: 2s;
    animation-delay: 0.5s;
  }
  
  .hide {
    animation: infinite rotate-last;
    animation-duration: 2s;
    animation-delay: 1.5s;
  }
  
  @keyframes rotate {
    0% {
      opacity: 0;
      transform: translate3d(0, 50px, 0);
    }
    50%,
    60% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(0, -35px, 0);
    }
  }
  
  @keyframes rotate-last {
    0% {
      opacity: 0;
      transform: translate3d(0, 50px, 0);
    }
  
    50%,
    60% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(0, -35px, 0);
    }
  }