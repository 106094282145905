@tailwind base;
@tailwind components;
@tailwind utilities;

#tasks > :first-child {
  height: max-content;
  align-self: self-end;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
  stroke-width: 3;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #fda99a;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
  /* width: 0px; */
  stroke-width: 3px;
}

#chartOne text tspan{
  color:white
}

#chartOne{
  background-color: black;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #000 }
}

#cycleinput input{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.nav-links {
    text-decoration: none;
    color: white;
    font-size: larger;
    font-weight: 600;
}

.nav-links:hover{
    color: orange;
}

.start-now-btn {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity));
    border-color: rgb(234 88 12 / var(--tw-bg-opacity));

}

.start-now-btn:hover {
    background: rgb(163, 87, 0);
}


.light-mode{
    filter: invert(1) hue-rotate(188deg);
}


/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  






  :root
  {
      /* --percentageRotate: 135; */
  }
    
  
  .chart {
    margin: 13px 9px;
    padding: 0;
    list-style-type: none;
  }
  
  .chart *,
  .chart::before {
    box-sizing: border-box;
  }
  
  .chart {
    position: relative;
    width: 150px;
    height: 75px;
    overflow: hidden;
  }
  
  .chart::before,
  .chart::after {
    position: absolute;
  }
  
  .chart::before {
    content: '';
    width: inherit;
    height: inherit;
    border: 7px solid #141414;
    border-bottom: none;
    border-top-left-radius: 175px;
    border-top-right-radius: 175px;
  }
  
  .chart div {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: inherit;
    /* border: 7px solid #2e2775; */
    border: 7px solid #141414;
    border-top: none;
    border-bottom-left-radius: 175px;
    border-bottom-right-radius: 175px;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-timing-function: linear;
    background: transparent;
  }
  
  .chart div:nth-child(1) {
    z-index: 4;
    border-color: #fda99a; 
    transition: all 2s;
    transform: rotate(calc(var(--percentageRotate,0) * 1deg));
  }
  
  .chart span {
    position: absolute;
    font-size: 18px;
    -webkit-backface-visibility: hidden;
    color: white;
    animation: fade-in .4s linear forwards;
  }
  
  .chart .candidate-one-percentage {
    top: 10px;
    left: -35px;
    transform: rotate(-90deg);
  }
  
  .chart .candidate-two-percentage {
    top: -35px;
    left: -35px;
    transform: rotate(-90deg);
  }
  
  @keyframes rotate-one {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(calc(--percentageRotate * 1deg));
    }
  } 






  .chart2::before{
    content: '';
    width: inherit;
    height: inherit;
    /* border: 12px solid #2e2775; */
    border: 12px solid #141414;
    /* border-bottom: none; */
    border-top-left-radius: 175px;
    border-top-right-radius: 175px;
    border-bottom-left-radius: 175px;
    border-bottom-right-radius: 175px;
  }


  
  .chart2 {
    margin: 16px 24px;
    padding: 0;
    list-style-type: none;
  }
  
  .chart2 *,
  .chart2::before {
    box-sizing: border-box;
  }
  
  .chart2 {
    position: relative;
    width: 180px;
    height: 180px;
    overflow: hidden;
  }
  
  .chart2::before,
  .chart2::after {
    position: absolute;
  }
  
  .chart2 div {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: inherit;
    /* border: 12px solid #2e2775; */
    border: 12px solid #141414;
    /* border-top: none; */
    border-bottom-left-radius: 175px;
    border-bottom-right-radius: 175px;
    border-top-left-radius: 175px;
    border-top-right-radius: 175px;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-timing-function: linear;
    background: transparent;
  }
  
  .chart2 div:nth-child(1) {
    z-index: 4;
    border-color: #fda99a; 
    transition: all 2s;
    transform: rotate(calc(var(--percentageRotate,0) * 1deg));
  }
  
  .chart2 span {
    position: absolute;
    font-size: 18px;
    -webkit-backface-visibility: hidden;
    color: white;
    animation: fade-in .4s linear forwards;
  }
  
  .chart2 .candidate-one-percentage2 {
    top: 10px;
    left: -35px;
    transform: rotate(-90deg);
  }
  
  .chart2 .candidate-two-percentage2 {
    top: -35px;
    left: -35px;
    transform: rotate(-90deg);
  }
  



  *, *:before, *:after {
    box-sizing: inherit;
  }
  .circle,.circle:before,.circle:after,.circle b:before,.circle b:after{
    content:""; 
    width:150px;
    height:150px;
    border-radius:50%;
  }
  .circle{
    position:relative;
    float:left;
    overflow:hidden;
    margin:20px;
    transform:rotate(0deg);/* make overflow work for nested transform*/
    /* background:#2e2775; */
    background:black;
  }
  .circle:before,.circle:after,.circle b:before,.circle b:after{
    position:absolute;
    left:0;
    top:0;
  }
  .circle:before{/*hide half of circle */
    border-radius:50% 0 0 50%;
    z-index:2;
    left:-50%;
    /* background:#2e2775; */
    background:black;
  }
  .circle b:after {
    background-image:linear-gradient(to right,#fda99a 50%,transparent 50%,transparent 100%);
    z-index:1;
    animation:3s spin linear forwards;
  }
  .circle b:before {
    background-image:linear-gradient(to left,#fda99a 50%,transparent 50%,transparent 100%);
    z-index:4;
    animation:3s spin2 linear forwards 3s;
    opacity:0;
  }
  
  .circle i {
    display:block;
    width:140px;
    height:140px;
    line-height:140px;
    font-size:150%;
    text-align:center;
    margin:4.25px;
    position:relative;
    z-index:5;
    /* background:#423888; */
    background:#141414;
    border-radius:50%;
  }

/* Circle 2 */
  .circle2,.circle2:before,.circle2:after,.circle2 b:before,.circle2 b:after{
    content:""; 
    width:100px;
    height:100px;
    border-radius:50%;
  }
  .circle2{
    /* position:relative; */
    position: absolute;
    top: 0px;
    left: 0px;
    float:left;
    overflow:hidden;
    margin:20px;
    transform:rotate(0deg);/* make overflow work for nested transform*/
    /* background:#2e2775; */
    background:black;
  }
  .circle2:before,.circle2:after,.circle2 b:before,.circle2 b:after{
    position:absolute;
    left:0;
    top:0;
  }
  .circle2:before{/*hide half of circle */
    border-radius:50% 0 0 50%;
    z-index:2;
    left:-50%;
    /* background:#2e2775; */
    background:black;
  }
  .circle2 b:after {
    background-image:linear-gradient(to right,#fda99a 50%,transparent 50%,transparent 100%);
    z-index:1;
    animation:3s spin linear forwards;
  }
  .circle2 b:before {
    background-image:linear-gradient(to left,#fda99a 50%,transparent 50%,transparent 100%);
    z-index:4;
    animation:3s spin2 linear forwards 3s;
    opacity:0;
  }
  
  .circle2 i {
    display:block;
    width:92px;
    height:92px;
    line-height:92px;
    font-size:150%;
    text-align:center;
    margin:3px;
    position:relative;
    z-index:5;
    /* background:#423888; */
    background:#141414;
    border-radius:50%;
  }


/* Circle 3 */
  .circle3,.circle3:before,.circle3:after,.circle3 b:before,.circle3 b:after{
    content:""; 
    width:60px;
    height:60px;
    border-radius:50%;
  }
  .circle3{
    /* position:relative; */
    position: absolute;
    top: 19px;
    left: 19px ;
    float:left;
    overflow:hidden;
    margin:20px;
    transform:rotate(0deg);/* make overflow work for nested transform*/
    /* background:#2e2775; */
    background:black;
  }
  .circle3:before,.circle3:after,.circle3 b:before,.circle3 b:after{
    position:absolute;
    left:0;
    top:0;
  }
  .circle3:before{/*hide half of circle */
    border-radius:50% 0 0 50%;
    z-index:2;
    left:-50%;
    background:#2e2775;
    background:black;
  }
  .circle3 b:after {
    background-image:linear-gradient(to right,#fda99a 50%,transparent 50%,transparent 100%);
    z-index:1;
    animation:3s spin linear forwards;
  }
  .circle3 b:before {
    background-image:linear-gradient(to left,#fda99a 50%,transparent 50%,transparent 100%);
    z-index:4;
    animation:3s spin2 linear forwards 3s;
    opacity:0;
  }
  
  .circle3 i {
    display:block;
    width:53px;
    height:53px;
    line-height:53px;
    font-size:150%;
    text-align:center;
    margin:3px;
    position:relative;
    z-index:5;
    /* background:#423888; */
    background:#141414;
    border-radius:50%;
  }




  .b2 b:after{animation-name:spinb}
  /* .b2 b:before{display:none} */
  .b2 b:before{animation-name: spinb2;}
  
  .b3 b:after{animation-name:spinc}
  .b3 b:before{display:none}
  
  .b4 b:after{animation-name:spind}
  .b4 b:before{animation-name:spind2}
  
  
  @keyframes spin{from {  transform:rotate(0)}to {transform:rotate(250deg)}}
  @keyframes spin2{from { transform:rotate(0);opacity:1}to {transform:rotate(90deg);opacity:1}}
  
  @keyframes spinb{from { transform:rotate(0)}to {transform:rotate(180deg)}}
  @keyframes spinb2{from { transform:rotate(0)}to {transform:rotate(80deg)}}
  
  @keyframes spinc{from { transform:rotate(0)}to {transform:rotate(90deg)}}
  /* @keyframes spinc2{from { transform:rotate(0)}to {transform:rotate(90deg)}} */
  
  @keyframes spind{from { transform:rotate(0)}to {transform:rotate(180deg)}}
  @keyframes spind2{from {  transform:rotate(0);opacity:1}to {transform:rotate(157deg);opacity:1}}



  /* Learnings */

.learnings {
  /* background: linear-gradient(#212121, #212121) padding-box,
  linear-gradient(145deg, transparent 35%, #0a89a8, #252e31) border-box;
  border: 2px solid transparent; */
  /* background: linear-gradient(#2cff1b96, #212121) padding-box, linear-gradient(145deg, transparent 35%, #a13707, #252e31) border-box; */
  background: linear-gradient(#0097a0, #003b45) padding-box, linear-gradient(145deg, transparent 35%, #a13707, #252e31) border-box;
    border: 2px solid #ba460000;
  border-radius: 8px;
}



/* Macro btn */


#btn-macro {
  --primary-color: #645bff;
  --secondary-color: #fff;
  --hover-color: #111;
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  box-sizing: border-box;
  border: 0;
  border-radius: 20px;
  color: var(--secondary-color);
  padding: 1em 1.8em;
  background: var(--primary-color);
  display: flex;
  transition: 0.2s background;
  align-items: center;
  gap: 0.6em;
  font-weight: bold;
}

#btn-macro .arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#btn-macro .arrow {
  margin-top: 1px;
  width: var(--arrow-width);
  background: var(--primary-color);
  height: var(--arrow-stroke);
  position: relative;
  transition: 0.2s;
}

#btn-macro .arrow::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  border: solid var(--secondary-color);
  border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
  display: inline-block;
  top: -3px;
  right: 3px;
  transition: 0.2s;
  padding: 3px;
  transform: rotate(-45deg);
}

#btn-macro:hover {
  background-color: var(--hover-color);
}

#btn-macro:hover .arrow {
  background: var(--secondary-color);
}

#btn-macro:hover .arrow:before {
  right: 0;
}



/* IntALL BTN 0 sidebar */

.install-button {
  border-radius: 0.25rem;
  -webkit-clip-path: polygon(0 0, 0 0, 100% 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 100%);
  clip-path: polygon(0 0, 0 0, 100% 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 100%);
}

.install-button:hover {
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 0.3s ease-in;
}

